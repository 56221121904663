import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import { store } from "./store/Store.js";
import "./registerServiceWorker";
import { APIEndpoint, APIServices, defaultToken, auth } from './constants/config';
// import PerfectScrollbar from "vue2-perfect-scrollbar";
import vSelect from 'vue-select';
import VueAnime from "vue-animejs";
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import VuePageTransition from "vue-page-transition";
import VueCompositionAPI from '@vue/composition-api'



import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);



// New Style SCSS
import "@/assets/scss/style.css";

// Old Style
import "./tailwind.css"; // Estilos tailwind


import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import 'vue-select/dist/vue-select.css';
import VueTailwind from 'vue-tailwind';
import Spanish from 'vue-tailwind/dist/l10n/es';
import {
  TModal,
  TDatepicker
} from 'vue-tailwind/dist/components';

// Configuración de font awesome
import { library } from "@fortawesome/fontawesome-svg-core"; //Librería general de fontawesome
import AOS from "aos";
import "aos/dist/aos.css";

/*  Icons   */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFontAwesome,
  faFacebookF,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import {
  faPaperPlane,
  faKey,
  faCommentDots,
  faUser,
  faUserEdit,
  faUserSecret,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faPlusCircle,
  faShoppingCart,
  faFileInvoice,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import VueSmoothScroll from 'vue2-smooth-scroll';
import TopBar from "@/components/TopBar/TopBar.vue";
library.add(
  faFileInvoice,
  faPaperPlane,
  faKey,
  faCommentDots,
  faUser,
  faUserEdit,
  faUserSecret,
  faFacebookF,
  faTwitter,
  faInstagram,
  faFontAwesome,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faPlusCircle,
  faShoppingCart,
  faCheckCircle,
  faExclamationTriangle,
);

import Loader from "./components/Loader.vue";
import TLabel from "./components/TailwindComponents/TLabel.vue";

Vue.use(VueAnime);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VueSmoothScroll);
Vue.use(VuePageTransition);
Vue.use(VueCompositionAPI);
Vue.component('t-label', TLabel);
Vue.component('t-loader', Loader);

const settings = {
  't-datepicker': {
    component: TDatepicker,
    props: {
      locale: Spanish,
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'rounded bg-white border inline-flex flex-wrap',
        view: 'w-64',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input: 'block px-3 w-full transition duration-100 ease-in-out border rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3'
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute rounded shadow-md border border-primary bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'mt-1 inline-flex w-full flex justify-center px-1',
        inputWrapper: '',
        input: 'placeholder-gray-400',
        clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'flex pt-2 px-3 items-center',
        navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-400',
        navigatorViewButtonBackIcon: 'fill-current text-gray-400',
        navigatorViewButtonMonth: 'text-gray-700 font-semibold',
        navigatorViewButtonYear: 'text-gray-500 ml-1',
        navigatorViewButtonYearRange: 'text-gray-500 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-gray-700 font-semibold',
        navigatorLabelYear: 'text-gray-500 ml-1',
        navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-purple-100 rounded-full ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-purple-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-primary',
        navigatorNextButtonIcon: 'text-primary',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-purple-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay: 'text-sm bg-purple-500 text-white w-full h-8 rounded-l-full',
        inRangeLastDay: 'text-sm bg-purple-500 text-white w-full h-8 rounded-r-full',
        inRangeDay: 'text-sm bg-purple-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-purple-500 text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay: 'text-sm rounded-full bg-purple-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay: 'text-sm rounded-full bg-purple-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-purple-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-purple-100 disabled:opacity-50 disabled:cursor-not-allowed border border-purple-500',
        monthWrapper: 'px-3 pt-2',
        selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-purple-500 text-white',
        activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-purple-100',
        month: 'text-sm rounded w-full h-12 mx-auto hover:bg-purple-100',
        yearWrapper: 'px-3 pt-2',
        year: 'text-sm rounded w-full h-12 mx-auto hover:bg-purple-100',
        selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-purple-500 text-white',
        activeYear: 'text-sm rounded w-full h-12 mx-auto bg-purple-100'
      },
      variants: {
        danger: {
          input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
          clearButton: 'hover:bg-red-200 text-red-500'
        }
      }
    }
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-75',
        wrapper: 'modal-content-wraper relative mx-auto z-50 px-3 py-12',
        modal: 'overflow-visible relative  rounded',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50'
      },
      classes: {
        overlay: 'bg-black',
        wrapper: 'max-w-lg',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-none border-gray-100 font-extralight font-2xl',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      variants: {
        form: {
          wrapper: 'w-full lg:w-paper',
        },
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50'
        }
      }
    }
  }
}

Vue.use(VueTailwind, settings)
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("appTopbarNav", TopBar);
Vue.component('v-select', vSelect)
// Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;


const axios_instance = axios.create({
  baseURL: APIEndpoint,
  headers: {
    'Accept': 'application/json'
  }
})

Vue.prototype.$http = axios_instance;
Vue.prototype.$auth = auth;
Vue.prototype.$APIEndpoint = APIEndpoint;
Vue.prototype.$APIServices = APIServices;

new Vue({
  created() {
    AOS.init();
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER", userData);
    } else {
      this.$http.defaults.headers.common[
        "Authorization"
      ] = `Token ${defaultToken}`;
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount("#app");
