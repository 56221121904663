var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar"}},[_c('div',{staticClass:"background-black",class:[_vm.isCollapsedSidebar ? 'is-collapse' : 'is-expanded'],on:{"click":function($event){return _vm.toogleHamburger()}}}),_vm._v(" "),_c('div',{class:[
    _vm.isCollapsedSidebar
    ? 'is-collapse'
    : 'is-expanded'
  ],attrs:{"id":"main-container"}},[_c('div',{attrs:{"id":"sidebar_menu"},on:{"click":function($event){return _vm.toogleHamburger()}}},[_c('lottie',{attrs:{"options":_vm.hamburguerOptions.defaultOptions,"height":50,"width":50},on:{"animCreated":_vm.handleAnimation,"data_ready":_vm.data_ready}})],1),_vm._v(" "),_c('div',{class:[
      _vm.isCollapsedSidebar
      ? 'is-collapse'
      : 'is-expanded'
    ],attrs:{"id":"sidebar-content"}},[_c('ul',_vm._l((_vm.sideBarLinks.routes),function(menu,index){return _c('div',{key:index},[(menu.child_routes == null)?_c('router-link',{class:[ _vm.isCollapsedSidebar ? '' : 'apear-2r' ],style:({ animationDelay: 0.3 + index * 0.1 + 's' }),attrs:{"to":menu.path}},[_c('a',{attrs:{"content":menu.menu_title},on:{"click":function($event){return _vm.toogleHamburger()}}},[_c('i',{class:menu.menu_icon}),_vm._v(" "),_c('span',[_vm._v(_vm._s(menu.menu_title))])])]):_vm._e(),_vm._v(" "),_c('br'),_c('br')],1)}),0),_vm._v(" "),_c('div',{class:[ _vm.isCollapsedSidebar ? '' : 'apear-2r' ],style:({ animationDelay: 0.3 + _vm.index * 0.1 + 's' }),attrs:{"id":"social_media"}},[_c('a',{attrs:{"href":"https://www.tiktok.com/@sabar.mx","target":"_blank"}},[_c('svg',{staticClass:"bi bi-tiktok",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"}})])]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.facebook.com/sabarbythb","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-f']}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.instagram.com/sabar.mx/","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'instagram']}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }