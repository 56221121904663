<template>

  <div id="TopBar">

    <app-sidebar-nav @click.native="setIsUserSideBarOpen({status: false, timeout: 0});"></app-sidebar-nav>

    <div @click="manageHomeClick" class="logo animate-fade-bottom cursor-pointer">
      <img src="./logo.png" alt="logo" class="h-16 md:h-16 lg:h-20" />
    </div>


    <div id="user-sidebar">

      <div class="main-container">

        <div class="login-elements cursor-pointer" @click="toogleUserSidebar()">
          <div class="relative mx-1 rounded-full border inline-block border-primary h-8 w-8 flex justify-center items-center sm:h-10 sm:w-10">
            <font-awesome-icon
              :icon="['fas', 'user']"
              class="fa-lg text-primary"
            />
          </div>


          <div class="relative hidden mx-2 inline-block h-8 flex justify-center items-center sm:flex sm:h-10"
          >
            {{ loggedIn && userData ? userData.name: 'Iniciar sesión'}}
          </div>

        </div>

        <router-link v-if="loggedIn" to="shopping-car" class="flex justify-center items-center mr-3 cursor-pointer">
          
          <div class="relative mx-1 sm:mx-5 h-8 w-8 flex justify-center items-center">
            <font-awesome-icon
              :icon="['fas', 'shopping-cart']"
              class="fa-lg text-primary"
            />
            <div
              class="rounded-full absolute -top-2 -right-2 animate-pulse text-primary w-5 h-5 flex justify-center items-center"
            >
              {{ shopingCount }}
            </div>

          </div>

        </router-link>

        <div v-else class="flex justify-center items-center mr-3">
          <div class="relative mx-1 sm:mx-5 h-8 w-8 flex justify-center items-center">
            <font-awesome-icon
              :icon="['fas', 'shopping-cart']"
              class="fa-lg text-primary"
            />
            <div
              class="rounded-full absolute -top-2 -right-2 animate-pulse text-primary w-5 h-5 flex justify-center items-center"
            >
              {{ shopingCount }}
            </div>
          </div>
        </div>
        
      </div>

      <app-user-sidebar :is_opened="isUserSideBarOpen" ref="usersidebar"></app-user-sidebar>
      <div
        @click="$refs['sidebar'].closeHamburger(); setIsUserSideBarOpen({status: false, timeout: 0});"
      >
        <vue-page-transition name="overlay-left-full">
        </vue-page-transition>
      </div>

    </div>


    <!-- wtd -->
    <!-- <t-modal
      header="NUEVA COTIZACIÓN"
      v-model="showModal"
    >
      <p class="px-10 text-justify mb-3 text-primary text-lg">Estas a punto de comenzar una nueva cotización, esto eliminará los items que tienes actualmente en el carrito. Aún conservarás los items que hayas comprado y pagado.</p>
      <p class="px-3 text-justify text-primary-dark text-lg">¿Deseas continuar y eliminar los items que habías cotizado?</p>
      <template v-slot:footer>
        <div class="flex justify-between">
          <button type="button" class="bg-primary text-white px-5 py-1 rounded-sm" @click="showModal=false">
            Cancelar
          </button>
          <button type="button" class="bg-danger text-white px-5 py-1 rounded-sm" @click="confirmGoHome">
            Eliminar
          </button>
        </div>
      </template>
    </t-modal> -->

  </div>
</template>

<script>
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapMutations, mapGetters } from "vuex";

import SideBar from "@/components/Sidebar/SideBar.vue";
import UserSidebar from "@/components/UserSidebar/UserSidebar.vue";

export default {
  name: "topbar",
  data: () => ({
    currentProfile: "",
    showModal: false
  }),
  components: {
    appSidebarNav: SideBar,
    appUserSidebar: UserSidebar,
  },
  computed: {
    ...mapGetters(["loggedIn", "shoppingCar","userData"]),
    shopingCount () {
      return this.$store.state.ShoppingCarStore.shoppingCar ? this.$store.state.ShoppingCarStore.shoppingCar.carItems.length : 0
    },
    isUserSideBarOpen() {
      return this.$store.state.GeneralConfigStore.isUserSideBarOpen;
    },
  },
  created() {

  },
  mounted(){
  },
  methods: {
    ...mapMutations(["toogleUserSidebar", ]),
    manageHomeClick() {
      if (this.shoppingCar.getItems().length) {
        this.showModal = true;
      } else {
        this.showModal = false;
        this.$router.push('/');
      }
    },
    confirmGoHome() {
      this.showModal = false;
      this.shoppingCar.reset();
      this.$router.push('/');
    }
  },
  watch: {}
};
</script>

<style></style>